



































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Global, User, Groups, Reports, Props, MapSettings } from '@/store';
import { Group } from '@/store/modules/Groups';
import { Watch } from 'vue-property-decorator';
import Gmap from '@/pages/mapView/components/map/GMap.vue';
import API, { Types } from '@/modules/API';
import OverviewTab from '@/pages/groups/GroupTabs/OverviewTab.vue';
import ControlTab from '@/pages/groups/GroupTabs/ControlTab.vue';
import AdvancedTab from '@/pages/groups/GroupTabs/AdvancedTab.vue';
import ConfirmDialog from '@/components/dialogs/ConfirmDialog.vue';

@Component({ components: {
    Gmap,
    OverviewTab,
    ControlTab,
    AdvancedTab,
    ConfirmDialog
}})
export default class GroupDetails extends Vue{
    @User.State('project') project;
    @Global.State('lang') lang;
    @Global.State('readonly_user') readonly_user;
    @Groups.State('list') groups;
    @Reports.State('reportsList') devices;
    @Reports.Getter('commissionedFixtures') commissionedFixtures;
    @Reports.State('devicesCount') devicesCount;
    @Props.State('list') projectProperties;
    @MapSettings.State('userMapFilters') userMapFilters;
    @MapSettings.Action('updateMapFilter') updateMapFilter;
    @Global.Action('throwNotify') throwNotify;

    group = null;
    loading = true;
    selectedTab = 'overview';
    confirmFilterCreate = false;
    loadingCreate = false;

    async created() {
        await this.loadData();
    }

    @Watch('devices')
    @Watch('devicesCount')
    async loadData() {
        if (this.devices.length !== this.devicesCount) return;
        this.loading = true;
        await this.setGroupDetails();
        this.loading = false;
    }

    async setGroupDetails(){
        const group_id = this.$route.params.id;
        this.group = await API.get(
            Types.PROJECTS,
            `${this.project.id}/groups/${group_id}`);
    }

    goTo(path){
        this.$router.push(path);
    }

    get groupDevices(){
        return this.group && this.group.devices 
            ? this.commissionedFixtures.filter((device) => this.group.devices.includes(device.id))
                .sort((device1, device2) => device1.name.localeCompare(device2.name))
            : [];
    }

    get groupTitle(){
        return this.group && this.group.name 
            ? `${this.$t('Group')}: ${this.group.name}`
            : `${this.$t('Group')}`;
    }

    async generateFilter(){
        this.loadingCreate = true;
        if (this.userMapFilters){
            const nameExist = this.userMapFilters.some((filter) => filter.name.toLowerCase() === this.group.name.toLowerCase());
            if (nameExist){
                this.throwNotify({
                    type: 'error', 
                    title: `${this.$t('Error')}!`, 
                    text: this.$t(`Couldn't create filter because filter view with same name already exists`) 
                });
                this.loadingCreate = false;
                this.confirmFilterCreate = false;
                return;
            }
            const path = this.userMapFilters.length ? this.getNextPath() : '/custom1';
            await this.createFilter(path);
        }
        this.loadingCreate = false;
        this.confirmFilterCreate = false;
    }

    async createFilter(path){
        const filter = {
            assetType: [],
            circuit: [],
            phase: [],
            classes: [],
            groups: [this.group.id],
            map: null,
            menuTitle: this.group.name,
            meta: {
                auth: true,
                company: true
            },
            name: this.group.name,
            parent: '/',
            path,
            realPath: path,
            status: ['disconnected', 'connected'],
            type: ['FIXTURE']
        };
        await this.updateMapFilter({id: this.project.id, filter});
    }

    getNextPath(){
        const existPaths = this.userMapFilters.map((filter) => +filter.path.replace('/custom', ''));
        const max = Math.max(...existPaths);
        return `/custom${max + 1}`;
    }

}
